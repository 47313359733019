/**
 * The #__env are placeholders that are going to be replaced with actual values during the Azure Deployment
 */

export const API_URL: URL = (() => {
  let base = import.meta.env.PUBLIC_API_URL || '#{__env.baseUrl__}#';
  /**
   * To properly resolve the relative references in the URL, the base should end with '/'
   * https://developer.mozilla.org/en-US/docs/Web/API/URL_API/Resolving_relative_references
   */
  if (!base.endsWith('/')) {
    base += '/';
  }

  if (!URL.canParse(base)) {
    throw new Error('Invalid URL injected for the API_URL config');
  }

  return new URL(base);
})();

export const AUTH_URL: URL = (() => {
  let base = import.meta.env.PUBLIC_AUTH_URL || '#{__env.authUrl__}#';
  /**
   * To properly resolve the relative references in the URL, the base should end with '/'
   * https://developer.mozilla.org/en-US/docs/Web/API/URL_API/Resolving_relative_references
   */
  if (!base.endsWith('/')) {
    base += '/';
  }

  if (!URL.canParse(base)) {
    throw new Error('Invalid URL injected for the AUTH_URL config');
  }

  return new URL(base);
})();

export const DROPZONE_URL: URL = (() => {
  let base = import.meta.env.PUBLIC_DROPZONE_URL || '#{__env.dropzoneFrontendUrl}#';
  /**
   * To properly resolve the relative references in the URL, the base should end with '/'
   * https://developer.mozilla.org/en-US/docs/Web/API/URL_API/Resolving_relative_references
   */
  if (!base.endsWith('/')) {
    base += '/';
  }

  if (!URL.canParse(base)) {
    throw new Error('Invalid URL injected for the DROPZONE_URL config');
  }

  return new URL(base);
})();

// See vite-end.d.ts

export const baseURL = Object.freeze(API_URL);
export const authUrl = Object.freeze(AUTH_URL);
export const dropzoneFrontendUrl = Object.freeze(DROPZONE_URL);

export const status = import.meta.env.PUBLIC_STATUS || 'online';
export const clientId = import.meta.env.PUBLIC_CLIENT_ID || '#{__env.clientId__}#';
export const supportAddress = import.meta.env.PUBLIC_SUPPORT_ADDRESS || '#{__env.supportAddress}#';
export const intercomId = import.meta.env.PUBLIC_INTERCOM_ID || '#{__env.intercomId}#';
export const logLevel = import.meta.env.PUBLIC_LOG_LEVEL || 'info';
